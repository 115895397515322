
    .s-button-group {


        &--vertical {
            .uk-button {
                margin-block-end:  calc(var(--global-margin) * 0.3)!important;

                &:last-of-type {
                    margin-block-end: 0;
                }
            }

            &.uk-flex-wrap {
                .uk-button {
                    margin-block-end:  calc(var(--global-margin) * 0.3)!important;

                    &:last-of-type {
                        margin-block-end: 0;
                    }
                }
            }
        }



        &--horizontal {
            .uk-button {
                margin-inline-end:  calc(var(--global-margin) * 0.3)!important;

                &:last-of-type {
                    margin-inline-end: 0;
                }
            }

            &.uk-flex-wrap {
                .uk-button {
                    margin-block-end:  calc(var(--global-margin) * 0.3)!important;

                    &:last-of-type {
                        margin-block-end: 0;
                    }
                }
            }
        }

        &--vertical.s-button-group--tight {
            .uk-button {
                margin-block-end:  0px!important;

                &:last-of-type {
                    margin-block-end: 0px;
                }
            }

            &.uk-flex-wrap {
                .uk-button {
                    margin-block-end:  0px!important;

                    &:last-of-type {
                        margin-block-end: 0px;
                    }
                }
            }
        }

        &--horizontal.s-button-group--tight {
            .uk-button {
                margin-inline-end:  0px!important;

                &:last-of-type {
                    margin-inline-end: 0px;
                }
            }

            &.uk-flex-wrap {
                .uk-button {
                    margin-block-end:  0px!important;

                    &:last-of-type {
                        margin-block-end: 0px;
                    }
                }
            }
        }



    }
